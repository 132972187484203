:root
{
  --background-color: #2b2b2b;
  --foreground-color: #1e1e1e;
  --main-text-color: white;
  --subtext-color: #eae2e2;
  --link-color: #cb6e56;
}

[data-theme="light"]
{
  --background-color: #ede9da;
  --foreground-color: #d9cbcb;
  --main-text-color: black;
  --subtext-color: #121212;
  --link-color: #144f7e;
}

.App {
  text-align: left;
}

a{
  color: var(--link-color);
}

.listItem
{
  height: fit-content;
  border-radius: 10px;
  background-color: var(--foreground-color);

}

.listItem .content
{
  overflow: hidden;
  max-height: 0px;
  transition: max-height 0.2s;
}
.listItem.dropped .content
{
  max-height: 40vh;
  transition: max-height 0.2s;

}

.listItem .heading
{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1vw;
}

.heading .dropped
{
  background-color: transparent;
  border: none;

  transform: rotateZ(180deg);
  transition: transform ease 0.2s;
}
.heading .up
{
  background-color: transparent;
  border: none;
  transform: rotateZ(0deg);
  transition: transform ease 0.2s;

}

#socials
{
  display: flex;
  align-items: center;
  justify-content: left;
}

#socials a
{
  padding-inline: 10px;
  display: flex;
  align-items: center;
  justify-content: left;
}

.listItem img
{
  float: left;
  paddingInline: 1vw;
  height: 3rem;
  margin-top: auto;
  margin-bottom: auto;
}

body
{
  background-color: var(--background-color);
}

#content
{
  margin-left: 10vw;
  margin-right: 10vw;
}

h1
{
  font-size: 6rem;
}

h2
{
  font-size: 3.5rem;
}


h1, h2, h3{
  color: var(--main-text-color);
}

p, ul, li{
  color: var(--subtext-color);
}

.about
{
  display: flex;
  justify-content: start;
  align-content: start;
  gap: 50px;
}

@media (max-width: 700px) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1rem;
  }

  p, a {
    font-size: 0.8rem;
  }

  .about {
    display: inline;
  }

  .about div {
    margin-top: 20px;
  }

  #socials {
    display: inline;
  }
  #socials a {
    margin: 10px 0;
  }
}

  @media (max-width: 1300px) {
    .about
    {
      display: inline;
    }
    .about div
    {
      margin-top: 20px;
    }
    .about #socials
    {
      justify-content: right;
      align-content: center;
    }
}



